import { FC, MouseEvent, useCallback, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import fileSaver from 'file-saver';

import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

import { Invoice } from 'features';
import { client, useErrorHandler } from 'lib';

type Props = {
  invoice: Invoice;
};

const InvoicePdfLink: FC<Props> = ({ invoice }) => {
  const { id, number } = invoice;
  const [loading, setLoading] = useState(false);
  const handleError = useErrorHandler();
  const theme = useTheme();

  const handleContractDownload = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setLoading(true);

      client
        .get(event.currentTarget.href, {
          params: {
            cacheBustTimestamp: Date.now(),
          },
          responseType: 'blob',
          headers: {
            Accept: 'application/octet-stream',
          },
        })
        .then(response => {
          setLoading(false);
          fileSaver.saveAs(response.data, `${number}.pdf`);
        })
        .catch(error => {
          setLoading(false);
          handleError(error);
        });
    },
    [handleError, number]
  );

  if (loading) return <CircularProgress size={theme.typography.fontSize} />;

  return (
    <Link
      href={`/api/invoices/${id}/pdf`}
      target="_blank"
      onClick={handleContractDownload}>
      {number}
    </Link>
  );
};

export default InvoicePdfLink;
