export const BASE = process.env.REACT_APP_BASE;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const DATE_FORMAT = 'YYYY-MM-DD';


export const getCookieDomain = (): string => {
    const parts = window.location.hostname.split(".")
    if (parts.length === 3) {
        return `.${parts[1]}.${parts[2]}`
    }
    return window.location.hostname
}