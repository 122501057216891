import { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Form, TextField } from '@brunas/dashboard';
import * as yup from 'yup';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import UserEditSkeleton from 'components/UserEditSkeleton';
import Section from 'components/Section';
import RolesInput from 'components/RolesInput';
import ActionBox from 'components/ActionBox';
import Breadcrumbs from 'components/Breadcrumbs';

import { useUpdateUser, useUser, User as UserType } from 'features';
import { Divider } from '@mui/material';

type FormValues = {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordRepeat: string;
  roles: string[];
};

const schema = yup.object().shape({
  email: yup.string().required().email(),
  phoneNumber: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
  password: yup.string(),
  passwordRepeat: yup.string(),
  roles: yup.array().of(yup.string()),
});

export function breadcrumb({ firstName, lastName, email }: UserType) {
  const segments = [];

  if (firstName) {
    segments.push(firstName);
  }

  if (lastName) {
    segments.push(lastName);
  }

  return segments.length > 0 ? segments.join(' ') : email;
}

const User: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation('User');

  const query = useUser(id!);
  const update = useUpdateUser(id!);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      update.mutate({
        id: id!,
        data: {
          ...query.data,
          ...values,

        },
      });
    },
    [id, query.data, update]
  );

  if (query.data) {
    const { email, roles, phoneNumber, firstName, lastName } = query.data;

    return (
      <>
        <Breadcrumbs
          breadcrumbs={[
            { label: 'USERS', href: '/users' },
            { label: breadcrumb(query.data), translate: false },
          ]}
        />

        <Form<FormValues>
          initial={{
            lastName: lastName ?? '',
            firstName: firstName ?? '',
            email: email ?? '',
            phoneNumber: phoneNumber ?? '',
            roles: roles ?? [],
            password: "",
            passwordRepeat: "",
          }}
          schema={schema}
          onSubmit={handleSubmit}>
          <Section sx={{ mb: 1 }}>
            <TextField fullWidth name="email" label={t('EMAIL')} />
          </Section>
          <Section sx={{ mb: 1 }}>
            <TextField fullWidth name="firstName" label={t('FIRSTNAME')} />
          </Section>
          <Section sx={{ mb: 1 }}>
            <TextField fullWidth name="lastName" label={t('LASTNAME')} />
          </Section>
          <Section sx={{ mb: 1 }}>
            <TextField fullWidth name="phoneNumber" label={t('PHONE_NUMBER')} />
          </Section>

          <Divider sx={{ mb: 1 }} />
          <Section sx={{ mb: 1 }} title={t("PASSWORD_CHANGE")}>
            <TextField fullWidth name="password" label={t('PASSWORD')} type='password' />
            <TextField fullWidth name="passwordRepeat" type='password' label={t('PASSWORD_REPEAT')} />
          </Section>

          <Divider sx={{ mb: 1 }} />
          <Section title={t('ROLES')}>
            <RolesInput name="roles" />
          </Section>

          <ActionBox>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={update.isLoading}
              startIcon={<SaveIcon />}>
              {t('SAVE')}
            </LoadingButton>
          </ActionBox>
        </Form>
      </>
    );
  }

  return <UserEditSkeleton />;
};

export default User;
